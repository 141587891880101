<template>
    <div class="homepage-slider" :class="{'dark': $store.state.settings.darkMode}">
        <Splide
            v-if="slides.length > 0"
            class="slides"
            :options="splideOptions"
            @splide:mounted="initSplide"
            ref="splide"
        >
            <SplideSlide
                v-for="slide in slides"
                :key="slide.id"
            >
                <router-link
                    v-if="slide.attributes.link"
                    :to="slide.attributes.link"
                    :class="{'invert': slide.attributes.invert}"
                >
                    <picture>
                        <source
                            v-if="slide.attributes.mobileImage.data"
                            media="(max-width: 720px)"
                            :srcset="$getImageUrl(slide.attributes.mobileImage.data.attributes.url)"
                        >
                        <img :src="$getImageUrl(slide.attributes.image.data.attributes.url)">
                    </picture>
                </router-link>
                <div v-else :class="{'invert': slide.attributes.invert}">
                    <picture>
                        <source
                            v-if="slide.attributes.mobileImage.data"
                            media="(max-width: 720px)"
                            :srcset="$getImageUrl(slide.attributes.mobileImage.data.attributes.url)"
                        >
                        <img :src="$getImageUrl(slide.attributes.image.data.attributes.url)">
                    </picture>
                </div>
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide'
    import { slidesQuery } from '@/clients/strapi'

    export default {
        name: 'Slides',

        components: {
            Splide,
            SplideSlide
        },
        
        data() {
            return {
                slides: [],

                splideOptions: {
                    type: 'loop',
                    rewind: true,
                    lazyLoad: true,
                    autoplay: true
                },

                splide: null,

                currentIndex: 0,
                splideList: null
            }
        },

        apollo: {
            slides: {
                query: slidesQuery,
                update: data => data.slides.data
            }
        },

        computed: {
            transform() {
                return `translateX(-${this.currentIndex * 100}%)`
            }
        },

        methods: {
            initSplide(splide) {
                var self = this
                this.splide = splide
                this.splide.on('move', function(newIndex){
                    self.currentIndex = newIndex
                })
                this.splideList = this.$refs.splide.$el.getElementsByClassName('splide__list')[0]
                this.splide.on('destroy', function(){
                    self.setTransform()
                })
            },

            setTransform() {
                this.splideList.style.transform = this.transform
            }
        }
    }
</script>

<style lang="scss">
    .homepage-slider {
        .slides {
            height: 100%;

            .splide__track,
            .splide__list {
                width: 100%;
                height: 100%;
                display: flex;

                .splide__slide {
                    width: 100%;
                    height: 100%;

                    a,
                    div {
                        width: 100%;
                        height: 100%;

                        picture {
                            height: inherit;
                            width: inherit;
                        }

                        img {
                            height: inherit;
                            width: inherit;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                }
            }

            .splide__arrow,
            .splide__pagination {
                .splide__pagination__page {
                    background: #666;

                    &.is-active {
                        background: #ccc;
                    }
                }
            }
        }

        &.dark {
            .slides {
                .splide__track,
                .splide__list {
                    .splide__slide {
                        a,
                        div {
                            &.invert {
                                img {
                                    filter: invert(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
