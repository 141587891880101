<template>
    <div>
        <AnimatedLogo v-if="!$config.enableShop"/>

        <Slides v-if="$config.enableShop"/>

        <LatestReleases/>

        <LatestVideos/>

        <ComingEvents/>

        <slot name="footer"/>
    </div>
</template>

<script>
    import AnimatedLogo from '@/components/page-blocks/AnimatedLogo'
    import Slides from '@/components/page-blocks/Slides'
    import LatestReleases from '@/components/page-blocks/LatestReleases'
    import LatestVideos from '@/components/page-blocks/LatestVideos'
    import ComingEvents from '@/components/page-blocks/ComingEvents'

    export default {
        name: 'Home',

        components: {
            AnimatedLogo,
            Slides,
            LatestReleases,
            LatestVideos,
            ComingEvents
        }
    }
</script>
