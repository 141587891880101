<template>
    <Wrapper>
        <Content>
            <h2>
                Aktuelle Videos auf
                <a
                    v-if="$store.state.allowEmbeds.youtube"
                    class="youtube"
                    target="_blank"
                    href="https://www.youtube.com/stuhrwerk"
                >
                    Stuhrwerk
                    <img src="@/assets/yt_icon_rgb.png">
                </a>
                <span v-else class="youtube no-link">
                    Stuhrwerk
                    <img src="@/assets/yt_icon_rgb.png">
                </span>
            </h2>
            <Grid
                :size="$store.state.settings.smallItems ? 3 : 2"
                v-if="latestVideos.length > 0"
            >
                <YoutubeVideo
                    v-for="video in latestVideos"
                    :key="video.videoId"
                    :videoId="video.videoId"
                    :title="video.title"
                />
            </Grid>
            <div
                class="forbidden"
                v-if="!$store.state.allowEmbeds.youtube && latestVideos.length == 0"
            >
                <p>
                    Du hast das Einbinden von YouTube Videos auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
                    <br>
                    Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
                    <br>
                </p>
                <Toggle v-model="allowEmbed" onLabel="Erlaubt" offLabel="Verboten"/>
            </div>
            <div class="center all-videos" v-if="$store.state.allowEmbeds.youtube">
                <a class="button small" target="_blank" href="https://www.youtube.com/stuhrwerk">
                    ► Alle Videos
                </a>
            </div>
        </Content>
    </Wrapper>
</template>

<script>
    import Wrapper from '@/components/Wrapper'
    import Content from '@/components/Content'
    import Grid from '@/components/Grid'
    import Toggle from '@/components/Toggle'
    import YoutubeVideo from '@/components/players/YoutubeVideo'

    export default {
        name: 'LatestVideos',

        components: {
            Wrapper,
            Content,
            Grid,
            Toggle,
            YoutubeVideo
        },

        data() {
            return {
                latestVideos: [],
                allowEmbed: this.$store.state.allowEmbeds.youtube
            }
        },

        methods: {
            loadLatestYoutubeVideos() {
                if(this.$store.state.allowEmbeds.youtube){
                    var self = this
                    this.$youtube.getLatestVideos(this.numVideos)
                    .then(function(res){
                        self.latestVideos = self.$youtube.procecssPlaylistItems(res)
                    })
                    .catch(function(err){
                        console.log(err)
                    })
                }
            }
        },

        computed: {
            numVideos() {
                return this.$store.state.settings.smallItems ? 6 : 4
            }
        },

        mounted() {
            this.loadLatestYoutubeVideos()
        },

        watch: {
            allowEmbed() {
                if(this.allowEmbed){
                    var self = this
                    setTimeout(function(){
                        self.$store.commit('allowEmbed', 'youtube')
                    }, 400)
                }
            },

            '$store.state.allowEmbeds.youtube': {
                handler() {
                    this.loadLatestYoutubeVideos()
                }
            },

            '$store.state.settings.smallItems': {
                handler() {
                    this.loadLatestYoutubeVideos()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    h2 {
        @include h2AsH1;

        .youtube {
            color: inherit;
            @include transition;

            &.no-link {
                &:hover {
                    color: inherit;
                }
            }

            img {
                height: 34px;

                @include breakpoint('mobile') {
                    height: 30px;
                }
            }

            &:hover {
                color: red;
            }
        }
    }

    .all-videos {
        margin-top: 30px;

        .button {
            background: $red !important;
            border-radius: 10px;
        }
    }

    .forbidden {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
        }
    }
</style>
