<template>
    <Wrapper variant="light">
        <Content>
            <h2>Aktuelle Releases</h2>
            <Grid
                v-if="!releases"
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="i in Array(3).fill(0).map((_, i) => i * i)"
                    :key="i"
                    url="#"
                    type="cover"
                />
            </Grid>
            <Grid
                v-else
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="release in releases"
                    :key="release.id"
                    :url="`/releases/${release.attributes.url_key}`"
                    :image="$getReleaseImage(release, 'small')"
                    :title_one="release.attributes.title"
                    :title_two="release.attributes.artist"
                    type="cover"
                />
            </Grid>
            <div class="center all-releases">
                <router-link class="button small" to="/releases">Alle Releases</router-link>
            </div>
        </Content>
    </Wrapper>
</template>

<script>
    import Wrapper from '@/components/Wrapper'
    import Content from '@/components/Content'
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import { releasesQuery } from '@/clients/strapi'

    export default {
        name: 'LatestReleases',

        components: {
            Wrapper,
            Content,
            Grid,
            GridItem
        },

        apollo: {
            releases: {
                query: releasesQuery,
                variables() {
                    return {
                        limit: this.$store.state.settings.smallItems ? 4 : 3
                    }
                },
                update: data => data.releases.data
            }
        },
    }
</script>

<style lang="scss" scoped>
    h2 {
        @include h2AsH1;
    }

    .all-releases {
        margin-top: 30px;
    }
</style>
