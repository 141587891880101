<template>
    <Wrapper v-if="events.length > 0" variant="light">
        <Content>
            <slot name="header"><h2>Kommende Events</h2></slot>
            <div v-if="events && events.length > 0">
                <Event
                    v-for="event in events"
                    :event="event"
                    :key="event.id"
                />
            </div>
            <h3 v-else class="no-events">Zur Zeit steht nichts an!</h3>
        </Content>
    </Wrapper>
</template>

<script>
    import Wrapper from '@/components/Wrapper'
    import Content from '@/components/Content'
    import Event from '@/components/Event'
    import moment from 'moment'
    import { eventsQuery, connectedEventsQuery } from '@/clients/strapi'

    export default {
        name: 'ComingEvents',

        components: {
            Wrapper,
            Content,
            Event
        },

        data() {
            return {
                events: []
            }
        },

        apollo: {
            events: {
                query() {
                    return this.query
                },
                variables() {
                    return this.variables
                },
                update: data => data.events.data
            }
        },

        computed: {
            onArtistPage() {
                return this.$route.name == 'Artist'
            },

            query() {
                if(this.onArtistPage){
                    return connectedEventsQuery
                }
                else {
                    return eventsQuery
                }
            },

            variables() {
                var variables = {
                    date: moment().format('YYYY-MM-DD')
                }
                if(this.onArtistPage){
                    variables.artistSlug = this.$route.params.url_key
                }
                else {
                    variables.limit = 10
                }
                return variables
            }
        }
    }
</script>

<style lang="scss" scoped>
    h2 {
        @include h2AsH1;
    }
</style>
