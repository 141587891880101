<template>
    <div class="animated-logo" :class="{dark: $store.state.settings.darkMode}">
        <img v-if="!$store.state.settings.darkMode" src="@/assets/suw.gif"/>
        <img v-else src="@/assets/logo.png"/>
    </div>
</template>

<script>
    export default {
        name: 'AnimatedLogo'
    }
</script>

<style lang="scss" scoped>
    .animated-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;

        @include breakpoint(mobile) {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        img {
            width: 100%;
            max-width: 500px;
            filter: brightness(1.4);
            box-sizing: border-box;
        }

        &.dark {
            img {
                filter: none;
                padding: 0 70px;

                @include breakpoint(mobile) {
                    padding: 0 50px;
                }
            }
        }
    }
</style>
